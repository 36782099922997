import { IAccount } from "../types/api/accounts.type";
import { ILocation } from "../types/api/location.type";
import { IOrganization } from "../types/api/organization.type";
import { IPermission } from "../types/api/permission.type";
import { IRole } from "../types/api/role.type";
import { ITenant } from "../types/api/tenant.type";

export const constructAccount = (account: IAccount) => {
  return {
    account_name: account.account_name,
    address_line_1: account.address_line_1 || "",
    address_line_2: account.address_line_2 || "",
    city: account.city || "",
    country: account.country || "",
    postal: account.postal || "",
    state: account.state || "",
    subdomain: account.subdomain || "",
  };
};

export const constructOrganization = (organization: IOrganization) => {
  return {
    account_id: organization.account_id,
    org_name: organization.org_name,
    parent_org: organization.parent_org,
  };
};

export const constructLocation = (location: ILocation) => {
  return {
    loc_name: location.loc_name,
    geo: location.geo,
    org_id: location.org_id,
    timezone: location.timezone,
  };
};

export const constructTenant = (tenant: ITenant) => {
  return {
    loc_id: tenant.loc_id,
    tenant_name: tenant.tenant_name,
    preferences: JSON.parse(tenant.preferences || null),
  };
};

export const constructRole = (roles: any[]) => {
  return roles.map((role: any) => {
    if (typeof role === "object") {
      role = role.role_id;
    }
    return role;
  });
};

export const constructTenantRole = (tenant_roles: any[]) => {
  return tenant_roles.map((tenant_role: any) => {
    if (!tenant_role.tenant_id.tenant_id || !tenant_role.tenant_id) {
      return {};
    }
    return {
      loc_id: tenant_role.loc_id.loc_id || tenant_role.loc_id,
      tenant_id: tenant_role.tenant_id.tenant_id || tenant_role.tenant_id,
      roles: constructRole(tenant_role.roles),
    };
  });
};

export const constructUser = (user: any) => {
  return {
    org_id: user.org_id.org_id || user.org_id,
    phone_number: user.phone_number || "",
    preferences: JSON.parse(user.preferences || {}),
    primary_email: user.primary_email || "",
    tenant_roles: constructTenantRole(user.tenant_roles),
    secondary_email: user.secondary_email || "",
    sms_number: user.sms_number || "",
  };
};

export const constructOperation = (operations: any[]) => {
  return operations.map((operation: any) => {
    if (typeof operations === "object") {
      operation = operation.operation_name;
    }
    return operation;
  });
};

export const constructPermissionDetail = (permission: IPermission) => {
  return {
    permission_desc: permission.permission_desc,
    operations: { ...permission.operations, operations: constructOperation(permission.operations?.operations) },
  };
};

export const constructRolePermissionDetail = (role: IRole) => {
  return {
    permissions: role.permissions.length && role.permissions?.map(({ permission_id }: any) => permission_id),
  };
};
