import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GroupsIcon from "@mui/icons-material/Groups";
import InfiniteScroll from "react-infinite-scroll-component";

import DetailCard from "../../../components/DetailCard";
import { FormInput } from "../../../components/FormInput";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { FormAutocomplete } from "../../../components/FormAutoComplete";
import useNotifications from "../../../components/NotificationProvider";
import ColumnHeader from "../../../components/ColumnHeader";
import LocationService from "../../../services/location.service";
import { getTimeZones } from "../../../global/timezones";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { constructLocation } from "../../../utils/utils";
import { IAccountsColumn } from "../../../types/app/accounts.type";
import { ILocation } from "../../../types/api/location.type";

import "./account.scss";

interface ILocationsColumn extends IAccountsColumn {}

const LocationsColumn = ({ setSelectedColumn }: ILocationsColumn) => {
  const { selectedAccount } = useStoreState((store) => store.account);
  const { selectedOrganization } = useStoreState((store) => store.organization);
  const { locations, selectedLocation, totalLocation, loading } = useStoreState((store) => store.location);
  const {
    setSelectedLocation,
    clearLocationState,
    getLocationsByOrganizationId,
    createLocation,
    editLocation,
    deleteLocation,
  } = useStoreActions((store) => store.location);
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = useState(1);
  const [selectedLocationDetail, setSelectedLocationDetail] = useState<any>({});
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { setNotification } = useNotifications();
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef<any>(null);

  const timezones = useMemo(getTimeZones, []);

  useEffect(() => {
    return () => {
      clearLocationState();
      resetSearchInput();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization.org_uuid]);

  useEffect(() => {
    loadData(1);
    setSelectedColumn({ id: 2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization.org_uuid, searchTerm]);

  const loadData = async (initialPage = 0) => {
    try {
      await getLocationsByOrganizationId({
        orgId: selectedOrganization.org_uuid as string,
        data: {
          count_per_page: 10,
          page_no: initialPage || page,
          filter: {
            loc_name: searchTerm,
          },
          show_deleted: false,
        },
      });

      setPage((initialPage || page) + 1);
    } catch (error) {}
  };

  const onSubmit = async (values: any) => {
    try {
      let loc_uuid = values.loc_uuid;
      values.org_id = selectedOrganization.org_id;
      values.geo = {};
      values.timezone = values.timezone.code;
      if (loc_uuid) {
        const constructedData = constructLocation(values);
        await editLocation({ data: constructedData, loc_uuid });
      } else {
        await createLocation(values);
      }
      setOpenLocationDialog(false);
      setNotification({
        message: `Location ${loc_uuid ? "updated" : "created"}successfully`,
        open: true,
      });
      loadData(1);
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLocation(selectedLocation.loc_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Location deleted successfully", open: true });
      setSelectedColumn({ id: 2, name: "organization" });
      loadData(1);
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleOnCardClick = async (data: ILocation) => {
    setSelectedColumn({ id: 3, name: "location" });
    setSelectedLocation(data);
    const result = await LocationService.getLocationById(data.loc_uuid as string);
    const locDetail = result.data;
    setSelectedLocationDetail({
      tenants: {
        count: locDetail.tenant_count,
        icon: <GroupsIcon />,
      },
    });
    setSelectedLocation(locDetail);
  };

  const handleEditClick = () => {
    reset({ ...selectedLocation });
    setOpenLocationDialog(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  const resetSearchInput = () => {
    if (searchRef.current !== null) {
      searchRef?.current.resetSearch();
    }
  };

  return (
    <>
      <Box sx={{ borderRight: 1, borderColor: "#5B5E67", pl: 1 }}>
        <ColumnHeader
          title={t("common:locations.title")}
          handleOnSearch={setSearchTerm}
          handleOnAdd={() => {
            setOpenLocationDialog(true);
          }}
          ref={searchRef}
        />
        <Box
          id="locationScroll"
          sx={{
            height: "calc(100vh - 150px)",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#757575",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: 2,
            },
            overflow: "auto",
            pr: 1,
          }}
        >
          <InfiniteScroll
            dataLength={locations.length}
            next={loadData}
            hasMore={locations.length < totalLocation}
            loader={<h4>Loading...</h4>}
            scrollableTarget="locationScroll"
          >
            {loading && (
              <Typography align="center" p={2}>
                Loading...
              </Typography>
            )}
            {locations.map((location: ILocation, index: number) => (
              <DetailCard
                key={location.loc_id}
                data={location}
                details={selectedLocationDetail}
                title={location.loc_name}
                subheader={location.timezone}
                id={location.loc_id}
                showDetailView={location.loc_id === selectedLocation.loc_id}
                handleOnCardClick={handleOnCardClick}
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
              />
            ))}
            {!locations.length && !loading && (
              <Typography align="center" p={2}>
                No records found
              </Typography>
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openLocationDialog}
        onClose={() => setOpenLocationDialog(false)}
      >
        <DialogTitle>{`${
          getValues("loc_id") ? "Edit" : "Create"
        } location`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Typography>{`${selectedAccount.account_name} > ${selectedOrganization.org_name}`}</Typography>
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={6}>
                  <FormInput
                    name="loc_name"
                    type="string"
                    id="loc_name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="geo"
                    type="string"
                    id="geo"
                    label="Geo location"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name="timezone"
                    type="string"
                    id="timezone"
                    label="Location timezone"
                    options={timezones}
                    labelKey="label"
                    valueKey="label"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              setOpenLocationDialog(false);
              reset({});
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="Are you sure you want to delete the selected location?"
        confirmationText={selectedLocation.loc_name}
      />
    </>
  );
};

export default React.memo(LocationsColumn);
