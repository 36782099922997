import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Stack,
  Divider,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  CardHeader,
  Grid,
  DialogActions,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Card, CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import DatabaseService from "../../services/database.service";
import { FormInput } from "../../components/FormInput";
import { FormCheckbox } from "../../components/FormCheckbox";
import useNotifications from "../../components/NotificationProvider";

const mockdata = [
  {
    id: 1,
    name: "vm-name",
    status: "UP",
    dns: "dbbox-prod.eastus.azure.com",
    ipAddress: "132.54.2.3",
    configuration: {
      diskSize: 200,
      cpu: 4,
      memory: 16,
      availableDiskSize: 100,
      availableCpu: 3,
      availableMemory: 4,
    },
  },
  {
    id: 2,
    name: "vm-name",
    status: "UP",
    dns: "dbbox-prod.eastus.azure.com",
    ipAddress: "132.54.2.3",
    configuration: {
      diskSize: 200,
      cpu: 4,
      memory: 16,
      availableDiskSize: 20,
      availableCpu: 2,
      availableMemory: 8,
    },
  },
];

const mocklogs = {
  dns: "dbbox-prod.eastus.azure.com",
  logs: [
    {
      timestamp: "2021-04-13 19:35:33.133 IST",
      logLevel: "FATAL",
      log: "could not create any TCP/IP sockets",
    },
    {
      timestamp: "2021-04-13 19:35:33.134 IST",
      logLevel: "LOG",
      log: "database system is shut down",
    },
    {
      timestamp: "2021-04-13 19:35:43.176 IST",
      logLevel: "LOG",
      log: "starting PostgreSQL 13.2 on x86_64-apple-darwin19.6.0, compiled by Apple clang version 12.0.0 (clang-1200.0.32.29), 64-bit",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "LOG",
      log: "could not bind IPv6 address '::1': Address already in use",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "HINT",
      log: "Is another postmaster already running on port 5432? If not, wait a few seconds and retry.",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "LOG",
      log: "could not bind IPv4 address '127.0.0.1': Address already in use",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "HINT",
      log: "Is another postmaster already running on port 5432? If not, wait a few seconds and retry.",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "WARNING",
      log: "could not create listen socket for 'localhost'",
    },
    {
      timestamp: "2021-04-13 19:35:43.177 IST",
      logLevel: "FATAL",
      log: "could not create any TCP/IP",
    },
  ],
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Database = () => {
  const { t } = useTranslation("common");
  const [showLogView, toggleLogView] = useState(false);
  const [openDBCreationForm, setOpenDBCreationForm] = useState(false);
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [data, setData] = useState<any>([]);
  const [logData, setLogs] = useState<{ dns: string; logs: Array<any> }>({
    dns: "",
    logs: [],
  });
  const watchUseExistingDB = watch("useExistingDB", false);
  const { setNotification } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const databases = await DatabaseService.getDatabases();
        console.log("databases---", databases);
        setData(mockdata);
      } catch (e) {
        console.error("----", e);
        setData(mockdata);
      }
    })();
  }, []);

  const showLogsOfDb = (id: number) => {
    console.log("id---", id);
    setLogs(mocklogs);
    setTimeout(() => {
      console.log("logData", logData);
    }, 2000);
  };

  const handleNavigation = (name: string) => {
    navigate(`/Database/${name}`);
  };

  const onSubmit = async (values: any) => {
    try {
      console.log("values---", values);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };

  return (
    <>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ marginBottom: "20px" }}
      >
        <Typography color="#777">{t("common:dashboard.Dashboard")}</Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "850px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  setOpenDBCreationForm(true);
                }}
                variant="contained"
              >
                Create
              </Button>
            }
            title={t("common:database.title")}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {data.length > 0 ? (
                  data.map((row: any) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          onClick={() => {
                            handleNavigation(row.name);
                          }}
                          component="h4"
                          sx={{ cursor: "pointer" }}
                        >
                          <Badge
                            color={row.status === "UP" ? "success" : "error"}
                            variant="dot"
                            sx={{ marginRight: "16px !important" }}
                          />
                          {row.name}
                        </Link>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                          sx={{ marginTop: "15px" }}
                        >
                          <Item>
                            CPU - <b>{row.configuration.cpu}</b>
                          </Item>
                          <Item>
                            Memory - <b>{row.configuration.memory}</b>
                          </Item>
                          <Item>
                            Disk - <b>{row.configuration.diskSize}</b>
                          </Item>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="caption"
                          display="block"
                          color="#888"
                          sx={{ fontWeight: "bold" }}
                        >
                          DNS:
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          color="#7AC231"
                        >
                          {row.dns}{" "}
                          <ContentCopyIcon
                            fontSize="small"
                            sx={{
                              color: "#fff",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(row.dns);
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="caption"
                          display="block"
                          color="#888"
                          sx={{ fontWeight: "bold" }}
                        >
                          IP:
                        </Typography>
                        <Link href={row.ipAddress} color="#79bcff">
                          {row.ipAddress}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <br />
                        <Button
                          variant="text"
                          onClick={() => {
                            showLogsOfDb(row.id);
                            toggleLogView(!showLogView);
                          }}
                        >
                          Logs
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No records found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Drawer
            anchor={"right"}
            open={showLogView}
            onClose={() => toggleLogView(false)}
            PaperProps={{
              sx: { width: "40%" },
            }}
          >
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Typography
                variant="caption"
                display="block"
                color="#888"
                sx={{ fontWeight: "bold", mb: 3 }}
              >
                DNS:{" "}
                <Typography
                  variant="body1"
                  component="div"
                  color="#7AC231"
                  sx={{ fontSize: "14px", marginBottom: "5px" }}
                >
                  {logData.dns}
                </Typography>
              </Typography>
              {logData.logs.map((row: any) => {
                return (
                  <>
                    <Typography
                      variant="body1"
                      component="div"
                      color="#7AC231"
                      sx={{ fontSize: "14px", marginBottom: "5px" }}
                    >
                      {row.timestamp}:{" "}
                      <Typography
                        variant="body1"
                        component="span"
                        color="#fff"
                        sx={{ fontSize: "inherit" }}
                      >
                        {row.logLevel}: {row.log}
                      </Typography>
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Drawer>
        </CardContent>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDBCreationForm}
        onClose={() => setOpenDBCreationForm(false)}
      >
        <DialogTitle>Create Database</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={4}>
                  <FormInput
                    name="name"
                    type="string"
                    id="name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="location"
                    type="string"
                    id="location"
                    label="Location"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="cpu"
                    type="number"
                    id="cpu"
                    label="Cpu"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="memory"
                    type="number"
                    id="memory"
                    label="Memory"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="dns"
                    type="string"
                    id="dns"
                    label="Dns"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="useExistingSchema"
                    id="useExistingSchema"
                    label="Use existing schema"
                    rules={{}}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="useExistingDB"
                    id="useExistingDB"
                    label="Use existing database"
                    rules={{}}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                {watchUseExistingDB && (
                  <>
                    <Grid item xs={4}>
                      <FormInput
                        name="dbpath"
                        type="text"
                        id="dbpath"
                        label="DB path"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenDBCreationForm(false)}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Provision
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Database;
