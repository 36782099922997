import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getDatabases = () => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/databases`);
};

const getDBDetail = (id: string | undefined) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/${id}`);
};

const createBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup`, data);
};

const restoreBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup/restore`, data);
};

const removeBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup/remove`, data);
};

const resampleDatabase = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/resample`, data);
};

const DatabaseService = {
  getDatabases,
  getDBDetail,
  createBackup,
  restoreBackup,
  removeBackup,
  resampleDatabase,
};
export default DatabaseService;
