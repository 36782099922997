import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import DetailCard from "../../../components/DetailCard";
import { FormInput } from "../../../components/FormInput";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import useNotifications from "../../../components/NotificationProvider";
import ColumnHeader from "../../../components/ColumnHeader";
import { FormCheckbox } from "../../../components/FormCheckbox";
import { FormTextEditor } from "../../../components/FormTextEditor";
import { FormSelect } from "../../../components/FormSelect";
import useLoader from "../../../components/LoaderProvider";
import TenantService from "../../../services/tenant.service";
import EnvironmentService from "../../../services/environment.service";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { constructTenant } from "../../../utils/utils";
import { IAccountsColumn } from "../../../types/app/accounts.type";
import {
  defaultTenantPreferences,
  environmentDBTiers,
} from "../../../global/constants";
import { ITenant } from "../../../types/api/tenant.type";
import {
  IEnvironment,
  IPhysicalEnvironment,
} from "../../../types/api/environment.type";

import "./account.scss";

interface ITenantsColumn extends IAccountsColumn {}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`environment-panel-${index}`}
      aria-labelledby={`environment-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `environment-${index}`,
    "aria-controls": `environment-panel-${index}`,
  };
};

const TenantsColumn = ({ setSelectedColumn }: ITenantsColumn) => {
  const { selectedAccount } = useStoreState((store) => store.account);
  const { selectedOrganization } = useStoreState((store) => store.organization);
  const { selectedLocation } = useStoreState((store) => store.location);
  const { tenants, selectedTenant, totalTenant, loading } = useStoreState(
    (store) => store.tenant
  );
  const { physicalEnvironment } = useStoreState((store) => store.environment);
  const {
    setSelectedTenant,
    clearTenantState,
    getTenantsByLocationId,
    createTenant,
    editTenant,
    deleteTenant,
  } = useStoreActions((store) => store.tenant);
  const {
    deleteEnvironment,
    getPhysicalEnvDetailsByEnvId,
    createPhysicalEnvironment,
    deletePhysicalEnvironment,
    setPhysicalEnvironment,
  } = useStoreActions((store) => store.environment);
  const { user } = useAuth0();
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const {
    control: envControl,
    register: envRegister,
    reset: envReset,
    handleSubmit: envHandleSubmit,
    formState: { errors: envErrors },
  } = useForm({});
  const [page, setPage] = useState(1);
  const [selectedTenantDetail, setSelectedTenantDetail] = useState<any>({});
  const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment>(
    {} as IEnvironment
  );
  const [openTenantDialog, setOpenTenantDialog] = useState(false);
  const [openEnvironmentDialog, setOpenEnvironmentDialog] = useState(false);
  const [openEnvFormDialog, setOpenEnvFormDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEnvDeleteConfirmation, setOpenEnvDeleteConfirmation] =
    useState(false);
  const [showSampleTenantPreferences, setShowSampleTenantPreferences] =
    useState(false);
  const [tabValue, setTabValue] = useState(0);
  const { setNotification } = useNotifications();
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef<any>(null);
  const { setLoading } = useLoader();

  useEffect(() => {
    return () => {
      clearTenantState();
      resetSearchInput();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation.loc_id]);

  useEffect(() => {
    loadData(1);
    setSelectedColumn({ id: 3 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation.loc_id, searchTerm]);

  useEffect(() => {
    if (tabValue === 1 || (openEnvFormDialog && selectedEnvironment.env_id)) {
      getPhysicalEnvDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  useEffect(() => {
    envReset({
      ...selectedEnvironment,
      ...physicalEnvironment,
      description: selectedEnvironment.env_desc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvironment, physicalEnvironment, openEnvFormDialog]);

  useEffect(() => {
    setShowSampleTenantPreferences(false);
  }, [openTenantDialog]);

  const getPhysicalEnvDetails = async () => {
    try {
      await getPhysicalEnvDetailsByEnvId({
        envId: selectedEnvironment.env_id as number,
      });
    } catch (error: any) {
      if (tabValue === 1) {
        setNotification({
          message: error?.message,
          open: true,
          type: "error",
        });
      }
    }
  };

  const loadData = async (initialPage = 0) => {
    try {
      await getTenantsByLocationId({
        locationId: selectedLocation.loc_uuid as string,
        data: {
          count_per_page: 10,
          page_no: initialPage || page,
          filter: {
            tenant_name: searchTerm,
          },
          show_deleted: false,
        },
      });
      setPage((initialPage || page) + 1);
    } catch (error) {}
  };

  const onSubmit = async (values: any) => {
    try {
      let tenant_uuid = values.tenant_uuid;
      values.loc_id = selectedLocation.loc_id;
      const constructedData = constructTenant(values);
      if (tenant_uuid) {
        await editTenant({ data: constructedData, tenant_uuid });
      } else {
        await createTenant(constructedData);
      }
      setOpenTenantDialog(false);
      setNotification({
        message: `Tenant ${tenant_uuid ? "updated" : "created"} successfully`,
        open: true,
      });
      loadData(1);
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const onEnvSubmit = async (values: any) => {
    try {
      setLoading(true);
      const selectedEnvId = selectedEnvironment.env_uuid;
      const userData = user as any;
      const tenantId = selectedTenant.tenant_id as number;

      const logicalEnvData = {
        description: values.description,
        is_batch: Boolean(values.is_batch),
        is_subscription: Boolean(values.is_subscription),
        is_trial: Boolean(values.is_trial),
        make_prod: Boolean(values.make_prod),
        rule_engine: Boolean(values.rule_engine),
        owner: userData["https://controlrooms.ai/user"].user_id as number,
        status: values.status || "starting",
        tenant_id: selectedTenant.tenant_id as number,
      };
      let envResponse = {} as { data: IEnvironment };
      if (selectedEnvId) {
        envResponse = await EnvironmentService.editEnvironment(
          logicalEnvData,
          selectedEnvId
        );
        setSelectedEnvironment(envResponse?.data);
        setEnvironmentList(selectedTenant.tenant_uuid as string);
      } else {
        envResponse = await EnvironmentService.createEnvironment(
          logicalEnvData
        );
      }
      const envID = envResponse?.data?.env_id;
      const envUUID = envResponse?.data?.env_uuid;
      const rule_engine = logicalEnvData.rule_engine;
      const physicalEnvData = {
        cpu_req: values.cpu_req,
        id: envID,
        uuid: envUUID,
        rule_engine: rule_engine,
        memory_req: values.memory_req,
        password: values.password,
        storage: values.storage,
        username: values.username,
        // tier: values.tier, // TODO: Need to enable this in future
      };
      if (!selectedEnvId) {
        await createPhysicalEnvironment({
          data: physicalEnvData,
          options: {
            headers: { "tenant-id": tenantId },
          },
        });
      }
      setOpenEnvFormDialog(false);
      setNotification({
        message: `Environment ${
          selectedEnvId ? "updated" : "created"
        } successfully`,
        open: true,
      });
      loadData(1);
      envReset({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setNotification({
        message: "Error occurred while creating environment",
        open: true,
        type: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTenant(selectedTenant.tenant_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Tenant deleted successfully", open: true });
      setSelectedColumn({ id: 3, name: "location" });
      loadData(1);
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleEnvDelete = async () => {
    try {
      setLoading(true);
      await Promise.all([
        deleteEnvironment(selectedEnvironment.env_uuid as string),
        deletePhysicalEnvironment(selectedEnvironment.env_id as number),
      ]);
      setOpenEnvDeleteConfirmation(false);
      setOpenEnvironmentDialog(false);
      setNotification({ message: "Tenant deleted successfully", open: true });
      setEnvironmentList(selectedTenant.tenant_uuid as string);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const setEnvironmentList = async (tenantId: string) => {
    const result = await TenantService.getTenantEnvironmentById(tenantId);
    const tenantEnvs = result.data;
    const envElements = tenantEnvs.map((env: IEnvironment) => (
      <ListItem
        key={env.env_id}
        sx={{ py: 0, minHeight: 32, cursor: "pointer" }}
        onClick={() => {
          setOpenEnvironmentDialog(true);
          setSelectedEnvironment(env);
          setTabValue(0);
        }}
        disableGutters
      >
        <Badge color="success" variant="dot" sx={{ mx: "16px !important" }} />
        <ListItemText secondary={`#${env.env_id} - ${env.env_desc}`} />
      </ListItem>
    ));
    setSelectedTenantDetail(envElements);
  };

  const handleOnCardClick = async (data: ITenant) => {
    setSelectedColumn({ id: 4, name: "tenant" });
    setSelectedTenant(data);
    setEnvironmentList(data.tenant_uuid as string);
  };

  const handleEditClick = () => {
    const preferences = JSON.stringify(
      selectedTenant.preferences,
      undefined,
      2
    );
    reset({ ...selectedTenant, preferences });
    setOpenTenantDialog(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  const resetSearchInput = () => {
    if (searchRef.current !== null) {
      searchRef?.current.resetSearch();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEnvEdit = () => {
    setOpenEnvFormDialog(true);
  };

  return (
    <>
      <Box sx={{ borderRight: 1, borderColor: "#5B5E67", pl: 1 }}>
        <ColumnHeader
          title={t("common:tenants.title")}
          handleOnSearch={setSearchTerm}
          handleOnAdd={() => {
            setOpenTenantDialog(true);
          }}
          ref={searchRef}
        />
        <Box
          id="tenantScroll"
          sx={{
            height: "calc(100vh - 150px)",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#757575",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: 2,
            },
            overflow: "auto",
            pr: 1,
          }}
        >
          <InfiniteScroll
            dataLength={tenants.length}
            next={loadData}
            hasMore={tenants.length < totalTenant}
            loader={<h4>Loading...</h4>}
            scrollableTarget="tenantScroll"
          >
            {loading && (
              <Typography align="center" p={2}>
                Loading...
              </Typography>
            )}
            {tenants.map((tenant: ITenant, index: number) => (
              <DetailCard
                key={tenant.tenant_id}
                data={tenant}
                details={selectedTenantDetail}
                title={tenant.tenant_name}
                subheader={`#${tenant.tenant_id}`}
                id={tenant.tenant_id}
                showDetailView={tenant.tenant_id === selectedTenant.tenant_id}
                handleOnCardClick={handleOnCardClick}
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
                hasAddEnv={true}
                handleAddEnv={() => {
                  setSelectedEnvironment({} as IEnvironment);
                  setPhysicalEnvironment({} as IPhysicalEnvironment);
                  setOpenEnvFormDialog(true);
                }}
              />
            ))}
            {!tenants.length && !loading && (
              <Typography align="center" p={2}>
                No records found
              </Typography>
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openTenantDialog}
        onClose={() => setOpenTenantDialog(false)}
      >
        <DialogTitle>{`${
          getValues("tenant_id") ? "Edit" : "Create"
        } tenant`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Typography>{`${selectedAccount.account_name} > ${selectedOrganization.org_name} > ${selectedLocation.loc_name}`}</Typography>
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={8}>
                  <FormInput
                    name="tenant_name"
                    type="string"
                    id="tenant_name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    mt={2}
                    color="#888"
                    sx={{ fontWeight: "bold" }}
                  >
                    Preferences
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <FormTextEditor
                    name="preferences"
                    id="preferences"
                    label="preferences"
                    language={"yml"}
                    minHeight={100}
                    defaultValue={JSON.stringify(
                      defaultTenantPreferences,
                      undefined,
                      2
                    )}
                    rules={{
                      validate: (value: string) => {
                        try {
                          return typeof JSON.parse(value) === "object"
                            ? true
                            : "Please enter a valid object";
                        } catch (err) {
                          return "Please enter a valid object";
                        }
                      },
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    mt={2}
                    color="#888"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => {
                      setShowSampleTenantPreferences(
                        !showSampleTenantPreferences
                      );
                    }}
                  >
                    <Link href="#">{`${
                      showSampleTenantPreferences ? "Close" : "Show"
                    } sample tenant preferences`}</Link>
                  </Typography>
                </Grid>
                {showSampleTenantPreferences && (
                  <Grid item xs={10}>
                    <FormTextEditor
                      name="samplePreferences"
                      id="samplePreferences"
                      label="samplePreferences"
                      language={"yml"}
                      minHeight={100}
                      rules={{}}
                      defaultValue={JSON.stringify(
                        defaultTenantPreferences,
                        undefined,
                        2
                      )}
                      readOnly
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              setOpenTenantDialog(false);
              reset({});
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openEnvironmentDialog}
        onClose={() => setOpenEnvironmentDialog(false)}
      >
        <DialogTitle>
          {selectedEnvironment.env_desc}
          <Box component="main">
            <Typography>{`${selectedAccount.account_name} > ${selectedOrganization.org_name} > ${selectedLocation.loc_name} > ${selectedTenant.tenant_name}`}</Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Logical" {...a11yProps(0)} />
                <Tab label="Physical" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Environment id: ${selectedEnvironment.env_id}`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Environment description: ${selectedEnvironment.env_desc}`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Environment type: ${
                    selectedEnvironment.is_batch ? "Batch" : "Stream"
                  }`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Subscription: ${selectedEnvironment.is_subscription}`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Trial: ${selectedEnvironment.is_trial}`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Resource group: ${selectedEnvironment.resource_group}`}
                />
              </ListItem>
              <ListItem disableGutters>
                <Badge
                  color="success"
                  variant="dot"
                  sx={{ mx: "16px !important" }}
                />
                <ListItemText
                  primary={`Status: ${selectedEnvironment.status}`}
                />
              </ListItem>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container sx={{ overflow: "auto" }}>
                {physicalEnvironment?.id ? (
                  <>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Environment id: ${
                            physicalEnvironment?.id || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Username: ${
                            physicalEnvironment?.username || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`CPUs: ${physicalEnvironment?.cpus || ""}`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`CPU backup: ${
                            physicalEnvironment?.cpu_bkp || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`CPU request: ${
                            physicalEnvironment?.cpu_req || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Memory: ${
                            physicalEnvironment?.memory || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Memory backup: ${
                            physicalEnvironment?.memory_bkp || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Memory request: ${
                            physicalEnvironment?.memory_req || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Storage: ${
                            physicalEnvironment?.storage || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Storage available: ${
                            physicalEnvironment?.storage_available || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Status: ${
                            physicalEnvironment?.status || ""
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`IP Address: ${
                            physicalEnvironment?.ip_address || "N/A"
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Node: ${
                            physicalEnvironment?.node || "N/A"
                          }`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                      <ListItem disableGutters>
                        <Badge
                          color="success"
                          variant="dot"
                          sx={{ mx: "16px !important" }}
                        />
                        <ListItemText
                          primary={`Age: ${physicalEnvironment?.age || "N/A"}`}
                        />
                      </ListItem>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={6} m={2}>
                    No data found
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              variant="outlined"
              sx={{ mx: 2 }}
              onClick={() => setOpenEnvDeleteConfirmation(true)}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              sx={{ mx: 2 }}
              onClick={() => handleEnvEdit()}
            >
              Edit
            </Button>
          </div>
          <Button
            sx={{ mr: 2 }}
            onClick={() => setOpenEnvironmentDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openEnvFormDialog}
        onClose={() => setOpenEnvFormDialog(false)}
      >
        <DialogTitle>{`Create environment`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Typography>{`${selectedAccount.account_name} > ${selectedOrganization.org_name} > ${selectedLocation.loc_name} > ${selectedTenant.tenant_name}`}</Typography>
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={12}>
                  Logical Entities
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="description"
                    type="string"
                    id="description"
                    label="Description"
                    required
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <FormCheckbox
                    name="is_batch"
                    id="is_batch"
                    label="Is batch"
                    required
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormCheckbox
                    name="is_subscription"
                    id="is_subscription"
                    label="Is subscription"
                    required
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormCheckbox
                    name="is_trial"
                    id="is_trial"
                    label="Is trial"
                    required
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormCheckbox
                    name="make_prod"
                    id="make_prod"
                    label="Make prod"
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormCheckbox
                    name="rule_engine"
                    id="rule_engine"
                    label="Rule Engine"
                    rules={{}}
                    control={envControl}
                    register={envRegister}
                    errors={envErrors}
                  />
                </Grid>
                {!selectedEnvironment?.env_id && (
                  <>
                    <Grid item xs={12}>
                      Physical Entities
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="username"
                        type="string"
                        id="username"
                        label="Username"
                        rules={{}}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="password"
                        type="password"
                        id="password"
                        label="Password"
                        helperText="Min 8 characters required"
                        rules={{
                          minLength: {
                            value: 8,
                            message: "Min 8 characters required",
                          },
                        }}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="cpu_req"
                        type="number"
                        id="cpu_req"
                        label="Minimum CPUs"
                        rules={{}}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="memory_req"
                        type="string"
                        id="memory_req"
                        label="Minimum Memory"
                        helperText="ex: 600Mi, 1Gi"
                        rules={{}}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="storage"
                        type="string"
                        id="storage"
                        label="Storage"
                        helperText="ex: 10Gi, 20Gi"
                        rules={{}}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormSelect
                        name="tier"
                        id="tier"
                        label="Tier"
                        options={environmentDBTiers}
                        rules={{}}
                        control={envControl}
                        register={envRegister}
                        errors={envErrors}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenEnvFormDialog(false)}>
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={envHandleSubmit(onEnvSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openEnvDeleteConfirmation}
        setOpen={setOpenEnvDeleteConfirmation}
        onProceed={handleEnvDelete}
        title="Delete environment"
        content="Are you sure you want to delete the selected environment?"
        confirmationText={selectedEnvironment.env_desc}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="Are you sure you want to delete the selected tenant?"
        confirmationText={selectedTenant.tenant_name}
      />
    </>
  );
};

export default React.memo(TenantsColumn);
