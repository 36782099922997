import { useEffect, useState } from "react";
import { DEFAULT_IDS } from "../../global/constants";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { FormInput } from "../../components/FormInput";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { CustomTableToolbar } from "../../components/CustomTableToolbar";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import useNotifications from "../../components/NotificationProvider";
import useLoader from "../../components/LoaderProvider";
import UserService from "../../services/user.service";
import AccountService from "../../services/account.service";
import OrganizationService from "../../services/organization.service";
import LocationService from "../../services/location.service";
import TenantService from "../../services/tenant.service";
import RoleService from "../../services/role.service";
import { constructUser } from "../../utils/utils";
import { IRowFilter } from "../../types/app/common.type";
import { IUser } from "../../types/api/user.type";
import { IAccount } from "../../types/api/accounts.type";
import { IOrganization } from "../../types/api/organization.type";
import { ILocation } from "../../types/api/location.type";
import { ITenantsResponse } from "../../types/api/tenant.type";
import { IRole } from "../../types/api/role.type";
import { FormTextEditor } from "../../components/FormTextEditor";

interface ITableRowsState {
  page: number;
  pageSize: number;
}

export default function Users() {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "tenant_roles",
  });
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rowFilter, setRowFilter] = useState<IRowFilter>({});
  const [tableRows, setTableRows] = useState<IUser[]>([]);
  const [selectedRow, setSelectedRow] = useState<IUser[]>([]);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [tenantsList, setTenantsList] = useState<any>({});
  const [roles, setRoles] = useState<IRole[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);
  const { setNotification } = useNotifications();
  const { setLoading } = useLoader();

  const columns: GridColDef[] = [
    { field: "user_id", headerName: "User ID", flex: 1 },
    { field: "primary_email", headerName: "Primary Email", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
    { field: "sms_number", headerName: "Sms Number", flex: 1 },
    { field: "org_id", headerName: "Organization ID", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.deleted) {
          return <></>;
        }
        return (
          <>
            <EditIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setOpenUserDialog(true);
                handleEditClick({ ...params.row });
              }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setSelectedRow([{ ...params.row }]);
                setOpenDeleteConfirmation(true);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsState, rowFilter, showDeleted]);

  useEffect(() => {
    getAccounts();
    getRoles();
    getLocations();
  }, []);

  useEffect(() => {
    if (!openUserDialog) {
      reset({ tenant_roles: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUserDialog]);

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setRowFilter({ primary_email: searchText });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleOnCreate = async () => {
    try {
      setLoading(true);
      if (DEFAULT_IDS.SANDBOX_LOCATION !== "" && DEFAULT_IDS.SANDBOX_TENANT !== ""){
        const CRLocation = (await LocationService.getLocationById(DEFAULT_IDS.SANDBOX_LOCATION)).data;
        const sandBoxTenant = (await TenantService.getTenantById(DEFAULT_IDS.SANDBOX_TENANT)).data;
        const defaultRoles = roles.filter((role) => role.role_id === 5);
        setValue("preferences", JSON.stringify({}, undefined, 2), {
          shouldValidate: true,
        });
        setValue(
          "tenant_roles",
          [{ loc_id: CRLocation, tenant_id: sandBoxTenant, roles: defaultRoles }],
          {
            shouldValidate: true,
          }
        );
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleEditClick = async (userData: IUser) => {
    try {
      setLoading(true);
      const userResponse: IUser = (
        await UserService.getUserByID(userData.user_uuid as string)
      ).data;
      const account_id = accounts.filter(
        (account) => account.account_id === userResponse.account?.account_id
      )[0];
      const org_id = (
        await OrganizationService.getOrganizationById(
          userResponse.account?.org_uuid as string
        )
      ).data;
      const preferences = JSON.stringify(
        userResponse.preferences,
        undefined,
        2
      );
      const tenantArr: any = [];
      let tenant_roles: any = [];
      if (userResponse.tenant_roles?.length) {
        userResponse.tenant_roles.map(async (tenant_role: any) => {
          tenantArr.push(
            TenantService.getTenantById(tenant_role?.tenant?.tenant_uuid)
          );
        });
        const tenantResult: ITenantsResponse[] = await Promise.all(tenantArr);
        tenant_roles = userResponse.tenant_roles.map(
          (tenant_role: any, index: number) => {
            return {
              loc_id: locations.filter(
                (location) => location.loc_id === tenant_role.loc_id
              )[0],
              tenant_id: tenantResult[index].data,
              roles: tenant_role.roles,
            };
          }
        );
      }
      reset({ ...userResponse, account_id, org_id, tenant_roles, preferences });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const result = await UserService.getUsers({
        count_per_page: rowsState.pageSize,
        page_no: rowsState.page + 1,
        filter: rowFilter,
        show_deleted: showDeleted,
      });
      setTotalRows(result.data?.total);
      setTableRows(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const getAccounts = async () => {
    try {
      const result = await AccountService.getAccounts({
        count_per_page: 1000,
        page_no: 1,
        filter: {},
        show_deleted: false,
      });
      setAccounts(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const getOrganizations = async (accountId: string) => {
    try {
      const result = await OrganizationService.getOrganizationsByAccountId(
        accountId,
        {
          count_per_page: 1000,
          page_no: 1,
          filter: {},
          show_deleted: false,
        }
      );
      setOrganizations(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const getLocations = async () => {
    try {
      const result = await LocationService.getLocations({
        count_per_page: 1000,
        page_no: 1,
        filter: {},
        show_deleted: false,
      });
      setLocations(result.data.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const getTenants = async (locationId: string) => {
    try {
      if (tenantsList[locationId]) {
        setTenantsList({ ...tenantsList });
        return;
      }
      const result = await TenantService.getTenantsByLocationId(locationId, {
        count_per_page: 1000,
        page_no: 1,
        filter: {},
        show_deleted: false,
      });
      setTenantsList({ ...tenantsList, [locationId]: result.data.results });
    } catch (e) {
      console.error("error", e);
    }
  };

  const getRoles = async () => {
    try {
      const result = await RoleService.getRoles({
        count_per_page: 1000,
        page_no: 1,
        filter: {},
        show_deleted: false,
      });
      setRoles(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let user_id = values.user_uuid;
      const constructedData = constructUser(values);
      if (user_id) {
        await UserService.editUser(constructedData, user_id);
      } else {
        await UserService.createUser(constructedData);
      }
      setOpenUserDialog(false);
      setNotification({
        message: `User ${user_id ? "updated" : "created"} successfully`,
        open: true,
      });
      getUsers();
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("error", e);
    }
  };

  const handleOnClose = () => {
    setOpenUserDialog(false);
    setTenantsList({});
  };

  const handleDelete = async () => {
    try {
      await UserService.deleteUser(selectedRow[0].user_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({
        message: "User deleted successfully",
        open: true,
      });
      getUsers();
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("error", e);
    }
  };

  const onFilterChange = (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => {
    console.log("filter data---", model, details);
    setRowFilter({ [model.items[0].columnField]: model.items[0].value });
  };

  const handleSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setRowFilter({});
  };

  return (
    <>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ marginBottom: "20px" }}
      >
        <Typography color="#777">Users</Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              onClick={() => {
                handleOnCreate();
                setOpenUserDialog(true);
              }}
            >
              Create
            </Button>
          }
          title={t("common:users.title")}
        />
        <CardContent
          sx={{
            paddingBottom: "16px !important",
            "& .deleted-row": {
              backgroundColor: "#4c3939",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            getRowId={(row) => row.user_id}
            rowsPerPageOptions={[rowsState.pageSize]}
            rowCount={totalRows}
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.row.deleted ? "deleted-row" : ""
            }
            paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) =>
              setRowsState((prev: ITableRowsState) => ({ ...prev, page }))
            }
            components={{
              Toolbar: CustomTableToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                searchValue: searchText,
                onSearchChange: (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  handleSearchChange(event.target.value);
                },
                showDeleted: showDeleted,
                onShowDeletedChange: (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  console.log(event.target.checked);
                  setShowDeleted(event.target.checked);
                },
                clearSearch: () => handleClearSearch(),
              },
            }}
          />
        </CardContent>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openUserDialog}
        onClose={handleOnClose}
      >
        <DialogTitle>{`${
          getValues("user_id") ? "Edit" : "Create"
        } user`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2}>
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      mt={2}
                      color="#888"
                      sx={{ fontWeight: "bold" }}
                    >
                      User detail
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      name="primary_email"
                      type="text"
                      id="primary_email"
                      label="Primary Email"
                      required
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      name="secondary_email"
                      type="text"
                      id="secondary_email"
                      label="Secondary Email"
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      name="phone_number"
                      type="number"
                      id="phone_number"
                      label="Phone Number"
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      name="sms_number"
                      type="number"
                      id="sms_number"
                      label="SMS Number"
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      mt={2}
                      color="#888"
                      sx={{ fontWeight: "bold" }}
                    >
                      Organization detail
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormAutocomplete
                      name="account_id"
                      id="account_id"
                      label="Account"
                      options={accounts}
                      labelKey="account_name"
                      valueKey="account_id"
                      handleOnChange={(value: IAccount) => {
                        setValue("org_id", "");
                        getOrganizations(value.account_uuid as string);
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormAutocomplete
                      name="org_id"
                      id="org_id"
                      label="Organization"
                      options={organizations}
                      labelKey="org_name"
                      valueKey="org_id"
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                </>
                {fields.length === 0 ? (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOnCreate();
                      }}
                    >
                      Add tenant roles
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        color="#888"
                        sx={{ fontWeight: "bold" }}
                      >
                        Tenant roles
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {fields.map((item, index) => (
                  <>
                    <Grid item xs={3}>
                      <FormAutocomplete
                        name={`tenant_roles[${index}].loc_id`}
                        id={`tenant_roles[${index}].loc_id`}
                        label="Location"
                        options={locations}
                        labelKey="loc_name"
                        valueKey="loc_uuid"
                        readOnly={
                          getValues(`tenant_roles[${index}].tenant_id`)
                            ?.tenant_id === 1
                        }
                        handleOnChange={(value: ILocation) => {
                          setValue(`tenant_roles[${index}].tenant_id`, "");
                          getTenants(value?.loc_uuid as string);
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormAutocomplete
                        name={`tenant_roles[${index}].tenant_id`}
                        id={`tenant_roles[${index}].tenant_id`}
                        label="Tenant"
                        options={
                          tenantsList[
                            getValues(`tenant_roles[${index}].loc_id`)?.loc_uuid
                          ] || []
                        }
                        labelKey="tenant_name"
                        valueKey="tenant_id"
                        readOnly={
                          getValues(`tenant_roles[${index}].tenant_id`)
                            ?.tenant_id === 1
                        }
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormAutocomplete
                        name={`tenant_roles[${index}].roles`}
                        id={`tenant_roles[${index}].roles`}
                        label="Role"
                        options={roles}
                        labelKey="role_desc"
                        valueKey="role_id"
                        multiple
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box>
                        {!(
                          getValues(`tenant_roles[${index}].tenant_id`)
                            ?.tenant_id === 1
                        ) && (
                          <Button
                            sx={{ mr: 2 }}
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                        {index === fields.length - 1 && (
                          <Button
                            variant="contained"
                            sx={{ mr: 2 }}
                            onClick={() =>
                              append({ loc_id: "", tenant_id: "", roles: [] })
                            }
                          >
                            Add
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </>
                ))}
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    mt={2}
                    color="#888"
                    sx={{ fontWeight: "bold" }}
                  >
                    Preferences
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <FormTextEditor
                    name="preferences"
                    id="preferences"
                    label="preferences"
                    language={"yml"}
                    minHeight={100}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="Are you sure you want to delete the selected user?"
        confirmationText={selectedRow[0]?.primary_email}
      />
    </>
  );
}
