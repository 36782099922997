import { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardContent, Box, Grid, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridCallbackDetails, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormInput } from "../../components/FormInput";
import { FormAsyncAutocomplete } from "../../components/FormAsyncAutoComplete";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { CustomTableToolbar } from "../../components/CustomTableToolbar";
import useNotifications from "../../components/NotificationProvider";
import TenantService from "../../services/tenant.service";
import LocationService from "../../services/location.service";
import { IRowFilter, ITableRowsState } from "../../types/app/common.type";
import { ITenant } from "../../types/api/tenant.type";

const Tenants = () => {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [openTenantDialog, setOpenTenantDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rowFilter, setRowFilter] = useState<IRowFilter>({});
  const [tableRows, setTableRows] = useState<ITenant[]>([]);
  const [selectedRow, setSelectedRow] = useState<ITenant[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const { setNotification } = useNotifications();

  const columns: GridColDef[] = [
    { field: "tenant_id", headerName: "Tenant ID", flex: 1 },
    { field: "tenant_name", headerName: "Name", flex: 1 },
    { field: "loc_id", headerName: "Location", flex: 1 },
    { field: "prod_env", headerName: "Production env", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.deleted) {
          return <></>;
        }
        return (
          <>
            <EditIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                console.log("edit---", params);
                setOpenTenantDialog(true);
                reset({ ...params.row });
              }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                console.log("delete---", params);
                setSelectedRow([{ ...params.row }]);
                setOpenDeleteConfirmation(true);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsState, rowFilter, showDeleted]);

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setRowFilter({ tenant_name: searchText });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getTenants = async () => {
    try {
      const result = await TenantService.getTenants({
        count_per_page: rowsState.pageSize,
        page_no: rowsState.page + 1,
        filter: rowFilter,
        show_deleted: showDeleted,
      });
      setTotalRows(result.data?.total);
      setTableRows(result.data.results);
    } catch (e) {
      console.error("----", e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let tenant_uuid = values.tenant_uuid;
      values.loc_id = values?.loc_id.loc_id;
      if (tenant_uuid) {
        delete values.tenant_id;
        await TenantService.editTenant(values, tenant_uuid);
      } else {
        await TenantService.createTenant(values);
      }
      setOpenTenantDialog(false);
      setNotification({
        message: `Tenant ${tenant_uuid ? "updated" : "created"}successfully`,
        open: true,
      });
      getTenants();
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      await TenantService.deleteTenant(selectedRow[0].tenant_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({
        message: "Tenant deleted successfully",
        open: true,
      });
      getTenants();
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const onFilterChange = (model: GridFilterModel, details: GridCallbackDetails) => {
    console.log("filter data---", model, details);
    setRowFilter({ [model.items[0].columnField]: model.items[0].value });
  };

  const handleSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setRowFilter({});
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              onClick={() => {
                setOpenTenantDialog(true);
              }}
            >
              Create
            </Button>
          }
          title={t("common:tenants.title")}
        />
        <CardContent
          sx={{
            paddingBottom: "16px !important",
            "& .deleted-row": {
              backgroundColor: "#4c3939",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            getRowId={(row) => row.tenant_id}
            rowsPerPageOptions={[rowsState.pageSize]}
            rowCount={totalRows}
            disableSelectionOnClick
            getRowClassName={(params) => (params.row.deleted ? "deleted-row" : "")}
            paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) => setRowsState((prev: ITableRowsState) => ({ ...prev, page }))}
            components={{
              Toolbar: CustomTableToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                searchValue: searchText,
                onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchChange(event.target.value);
                },
                showDeleted: showDeleted,
                onShowDeletedChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event.target.checked);
                  setShowDeleted(event.target.checked);
                },
                clearSearch: () => handleClearSearch(),
              },
            }}
          />
        </CardContent>
      </Card>
      <Dialog fullWidth={true} maxWidth="md" open={openTenantDialog} onClose={() => setOpenTenantDialog(false)}>
        <DialogTitle>{`${getValues("tenant_id") ? "Edit" : "Create"} tenant`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2} mt={1} pr={2} pb={2} sx={{ overflow: "auto" }}>
                <Grid item xs={6}>
                  <FormInput
                    name="tenant_name"
                    type="string"
                    id="tenant_name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAsyncAutocomplete
                    name="loc_id"
                    id="loc_id"
                    label="Location"
                    getOptions={(searchTerm: string) =>
                      LocationService.getLocations({
                        count_per_page: 10,
                        page_no: 1,
                        filter: { loc_name: searchTerm },
                        show_deleted: false,
                      })
                    }
                    labelKey="loc_name"
                    valueKey="loc_id"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="prod_env"
                    type="number"
                    id="prod_env"
                    label="Production env"
                    rules={{
                      required: "This field is required",
                      valueAsNumber: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenTenantDialog(false)}>
            Cancel
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="Are you sure you want to delete the selected tenant?"
        confirmationText={selectedRow[0]?.tenant_name}
      />
    </>
  );
};

export default Tenants;
