import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  Typography,
  TextFieldProps,
} from "@mui/material";
import { FieldProps } from "./types";

export interface FormAutocompleteProps extends FieldProps {
  labelKey: string;
  valueKey: string;
  multiple?: boolean;
  creatable?: boolean;
  handleOnChange?: (newValue: any) => void;
}

const filter = createFilterOptions<any>();

export const FormAutocomplete = ({
  id,
  name,
  label,
  required,
  options,
  labelKey,
  valueKey,
  multiple,
  creatable,
  defaultValue,
  readOnly,
  handleOnChange,
  control,
  errors,
  register,
  rules,
}: FormAutocompleteProps): JSX.Element => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <Autocomplete
              id={id}
              multiple={multiple}
              freeSolo={creatable}
              readOnly={readOnly}
              value={value || (multiple ? [] : "")}
              options={options || []}
              getOptionLabel={(option) =>
                option[labelKey] ?? option
              }
              isOptionEqualToValue={(option: any, value: any) => {
                return (
                  value === undefined ||
                  value === "" ||
                  option === value ||
                  option[valueKey] === value[valueKey]
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  required={required}
                  label={label}
                />
              )}
              {...(register && register(name, rules))}
              onChange={(event, option) => {
                if (typeof option === "string" && option.includes("Add ", 0)) {
                  // Create a new value from the user input
                  onChange(option.split(" ")[1]?.replace(/^"|"$/g, ""));
                } else {
                  // Set default option
                  onChange(option);
                }
                if (handleOnChange) {
                  handleOnChange(option);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting && creatable) {
                  filtered.push(`Add "${inputValue}"`);
                }
                return filtered;
              }}
            />
          </>
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error">{message}</Typography>
        )}
      />
    </>
  );
};
